import "./index.scss";
import "@splidejs/splide/dist/css/splide.min.css";
import Splide from "@splidejs/splide";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Viewer from 'viewerjs';


new Viewer(document.getElementById('slider'), {
  movable: false,
  title: false,
  toolbar: {
    zoomIn: false,
    zoomOut: false,
    oneToOne: false,
    reset: true,
    prev: true,
    play: {
      show: true,
      size: 'large',
    },
    next: true,
    rotateLeft: false,
    rotateRight: false,
    flipHorizontal: false,
    flipVertical: false,
  },
  transition: false
});

// -------- Menü animáció --------
const hamburger = document.getElementById("hamburger");
const mobileMenu = document.querySelector(".mobile") as HTMLElement;
const navbarBrand = document.querySelector(".brand a") as HTMLElement;
const links = document.querySelectorAll(".navigation a");
const navbar = document.getElementById("navbar");

hamburger.addEventListener("click", () => {
  hamburger.classList.toggle("open");
  if (hamburger.classList.contains("open")) {
    mobileMenu.style.display = "flex";
    navbarBrand.classList.add("colorWhite");
  } else {
    mobileMenu.style.display = "none";
    navbarBrand.classList.remove("colorWhite");
  }
});

links.forEach((link) => {
  link.addEventListener("click", () => {
    mobileMenu.style.display = "none";
    hamburger.classList.remove("open");
  });
});

navbarBrand.addEventListener("click", () => {
  mobileMenu.style.display = "none";
  hamburger.classList.remove("open");
  navbarBrand.classList.remove("colorWhite");
});

window.onscroll = function () {
  scrollFunction();
};
function scrollFunction() {
  if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
    navbar.classList.add("scrolled");
  } else {
    navbar.classList.remove("scrolled");
  }
}

// -------- Slider --------
new Splide(".splide", {
  type: "loop",
  perPage: 4,
  perMove: 1,
  autoWidth: true,
  gap: "1em",
  autoplay: true,
  interval: 3000,
  pauseOnHover: true,
  focus: "center",
  breakpoints: {
    1280: {
      perPage: 3,
    },
    992: {
      perPage: 2,
    },
    576: {
      perPage: 1,
    },
  },
}).mount();

gsap.registerPlugin(ScrollTrigger);

gsap.to("#alpin105", { duration: 1.4, opacity: 1, delay: 0.2 });
gsap.to("#navbar", { duration: 0.5, opacity: 1, y: 0, delay: 0.4 });
gsap.to("#bannerH2", { duration: 0.6, opacity: 1, x: 0, delay: 0.7 });
gsap.to("#bannerH3", { duration: 0.6, opacity: 1, x: 0, delay: 0.9 });
gsap.to("#btn-services", { duration: 0.6, opacity: 1, x: 0, delay: 1.0 });

gsap.fromTo(
  "#services-heading",
    {
      yPercent: 50,
      opacity: 0,
      autoAlpha: 0,
    },
    {
      duration: 0.6,
      yPercent: 0,
      opacity: 1,
      autoAlpha: 1,
      delay: 0,
      scrollTrigger: {
        trigger: "#services-heading",
        start: "top 90%",
        end: "bottom",
        // markers: true,
      },
    }
);

gsap.fromTo(
  "#services-sub-heading",
  {
    scale: 0,
    yPercent: 100,
    autoAlpha: 0,
  },
  {
    duration: 0.6,
    yPercent: 0,
    scale: 1,
    autoAlpha: 1,
    scrollTrigger: {
      trigger: "#services-sub-heading",
      start: "top 90%",
      end: "bottom",
      // markers: true,
    },
  }
);

const gridItem = gsap.utils.toArray(".grid-item");

gridItem.forEach((gridItem: any, i) => {
  gsap.fromTo(
    gridItem,
    {
      autoAlpha: 0,
      yPercent: 10,
      opacity: 0
    },
    {
      autoAlpha: 1,
      duration: 0.6,
      opacity: 1,
      yPercent: 0,
      height: "auto",
      delay: i * 0.1,
      scrollTrigger: {
        trigger: gridItem,
        start: "top 90%",
        // markers: true,
      },
    }
  );
});

gsap.fromTo(
  "#about-sub-heading",
    {
      yPercent: 50,
      autoAlpha: 0,
      opacity: 0,
    },
    {
      duration: 0.6,
      yPercent: 0,
      opacity: 1,
      autoAlpha: 1,
      delay: 0.3,
      scrollTrigger: {
        trigger: "#about-sub-heading",
        start: "top 90%",
        end: "bottom",
        // markers: true,
      },
    }
);
gsap.fromTo(
  "#about-heading",
  {
    yPercent: 50,
    autoAlpha: 0,
  },
  {
    duration: 0.6,
    yPercent: 0,
    autoAlpha: 1,
    scrollTrigger: {
      trigger: "#about-heading",
      start: "top 90%",
      end: "bottom",
      // markers: true,
    },
  }
);

gsap.fromTo(
  "#slider",
  {
    yPercent: 50,
    autoAlpha: 0,
  },
  {
    duration: 0.6,
    yPercent: 0,
    autoAlpha: 1,
    scrollTrigger: {
      trigger: "#slider",
      start: "top 90%",
      end: "bottom",
      // markers: true,
    },
  }
);

gsap.fromTo(
  "#contact-heading",
    {
      yPercent: 50,
      opacity: 0,
      autoAlpha: 0,
    },
    {
      duration: 0.6,
      yPercent: 0,
      opacity: 1,
      autoAlpha: 1,
      delay: 0,
      scrollTrigger: {
        trigger: "#contact-heading",
        start: "top 90%",
        end: "bottom",
        // markers: true,
      },
    }
);

gsap.fromTo(
  "#contact-sub-heading",
    {
      yPercent: 50,
      opacity: 0,
      autoAlpha: 0,
    },
    {
      duration: 0.6,
      yPercent: 0,
      opacity: 1,
      autoAlpha: 1,
      delay: 0.2,
      scrollTrigger: {
        trigger: "#contact-heading",
        start: "top 90%",
        end: "bottom",
        // markers: true,
      },
    }
);

gsap.fromTo(
  "#first-link",
    {
      yPercent: 50,
      opacity: 0,
      autoAlpha: 0,
    },
    {
      duration: 0.6,
      yPercent: 0,
      opacity: 1,
      autoAlpha: 1,
      delay: 0,
      scrollTrigger: {
        trigger: "#first-link",
        start: "top 90%",
        end: "bottom",
        // markers: true,
      },
    }
);

gsap.fromTo(
  "#second-link",
    {
      yPercent: 50,
      opacity: 0,
      autoAlpha: 0,
    },
    {
      duration: 0.6,
      yPercent: 0,
      opacity: 1,
      autoAlpha: 1,
      delay: 0.2,
      scrollTrigger: {
        trigger: "#first-link",
        start: "top 90%",
        end: "bottom",
        // markers: true,
      },
    }
);

gsap.fromTo(
  "#third-link",
    {
      yPercent: 50,
      opacity: 0,
      autoAlpha: 0,
    },
    {
      duration: 0.6,
      yPercent: 0,
      opacity: 1,
      autoAlpha: 1,
      delay: 0.4,
      scrollTrigger: {
        trigger: "#first-link",
        start: "top 90%",
        end: "bottom",
        // markers: true,
      },
    }
);

gsap.fromTo(
  "#fourth-link",
    {
      yPercent: 50,
      opacity: 0,
      autoAlpha: 0,
    },
    {
      duration: 0.6,
      yPercent: 0,
      opacity: 1,
      autoAlpha: 1,
      delay: 0.6,
      scrollTrigger: {
        trigger: "#first-link",
        start: "top 90%",
        end: "bottom",
        // markers: true,
      },
    }
);


gsap.fromTo(
    "#alpinrol-heading",
    {
      yPercent: 50,
      autoAlpha: 0,
      opacity: 0,
    },
    {
      duration: 0.6,
      yPercent: 0,
      opacity: 1,
      autoAlpha: 1,
      delay: 0.3,
      scrollTrigger: {
        trigger: "#alpinrol-heading",
        start: "top 90%",
        end: "bottom",
        // markers: true,
      },
    }
);

gsap.fromTo(
    "#alpinrol-content",
    {
      xPercent: -10,
      autoAlpha: 0,
      opacity: 0,
    },
    {
      duration: 0.6,
      xPercent: 0,
      opacity: 1,
      autoAlpha: 1,
      delay: 0.3,
      scrollTrigger: {
        trigger: "#alpinrol-heading",
        start: "top 90%",
        end: "bottom",
        // markers: true,
      },
    }
);
gsap.fromTo(
    "#alpinrol-image",
    {
      xPercent: 10,
      autoAlpha: 0,
      opacity: 0,
    },
    {
      duration: 0.6,
      xPercent: 0,
      opacity: 1,
      autoAlpha: 1,
      delay: 0.3,
      scrollTrigger: {
        trigger: "#alpinrol-heading",
        start: "top 90%",
        end: "bottom",
        // markers: true,
      },
    }
);
